import { useState } from 'react';

const useShowModal = () => {
  const [showModal, setShowModal] = useState(false);
  const toggleModal = () => {
    setShowModal(!showModal);
  };
  return { showModal, toggleModal };
};

export default useShowModal;
