import React from 'react';
import { useTheme, Grid, styled } from '@mui/material';

const StyledGrid = styled(Grid)(
  ({ theme }) => `
    &&.twoColumnTextGrid {
      width: 100%;
      margin-top: 0;
      margin-left: 0;
      p {
        margin-top: 0;
      }
      ${theme.breakpoints.only('xs')} {
        padding-top: ${theme.spacing(2)};
        gap: 0;
      }
      ${theme.breakpoints.only('sm')} {
        padding-top: ${theme.spacing(3)};
      }
      ${theme.breakpoints.only('md')} {
        padding-top: ${theme.spacing(5)};
      }
      ${theme.breakpoints.up('lg')} {
        padding-top: ${theme.spacing(5)};
      }
      .MuiGrid-item {
        padding-top: 0;
      }
      .MuiGrid-item:not(.row-reverse):first-of-type {
        padding-left: 0;
      }
    }
  `,
);

/**
 * Container grid for two equally-spaced columns.
 */
const TwoColumnTextGrid: React.FC<{
  children: React.ReactNode;
  order?: number;
  style?: React.CSSProperties;
  direction?: 'row' | 'column' | 'row-reverse' | 'column-reverse';
}> = ({ children, order, style, direction }) => {
  const theme = useTheme();
  return (
    <StyledGrid
      className={`twoColumnTextGrid ${direction} textAndImage`}
      container
      xs={12}
      spacing={{
        xs: theme.spacing(0.125),
        sm: theme.spacing(3),
        md: theme.spacing(4),
      }}
      direction={
        direction || {
          xs: 'column',
          sm: 'row',
        }
      }
      order={order || 0}
      style={style}
    >
      {children}
    </StyledGrid>
  );
};

export default TwoColumnTextGrid;
