import React from 'react';
import { Box, styled } from '@mui/material';
import Modal from '../modal';

// Styles to get a responsive iframe.
const VideoContainer = styled(Box)`
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
  && iframe,
  && object,
  && embed {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
`;

interface VideoModalProps {
  title: string;
  videoId: string;
  open: boolean;
  onClose: () => void;
}

const VideoModal: React.FC<VideoModalProps> = ({
  title,
  videoId,
  open,
  onClose,
}) => (
  <Modal title="" open={open} onClose={() => onClose()}>
    <VideoContainer
      className="videoContainer"
      margin={{
        xs: '33% 0',
        sm: '25% 0',
        md: '0',
      }}
    >
      <iframe
        src={`https://www.youtube.com/embed/${videoId}`}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </VideoContainer>
  </Modal>
);

export default VideoModal;
