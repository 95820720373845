import React from 'react';
import { Grid, Typography, useTheme } from '@mui/material';
import { RichText } from '@graphcms/rich-text-react-renderer';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import { GraphCmsRichText } from '../../GlobalState';
import SectionContainerGrid from '../sectionContainerGrid';
import TwoColumnTextGrid from '../twoColumnTextGrid';
import Image from '../../Image';
import StyledType from '../styledType';

const VariantOne: React.FC<{
  title: string;
  subtitle?: string;
  image: IGatsbyImageData;
  imageAlt: string;
  textBlocks: Array<GraphCmsRichText>;
}> = ({ title, subtitle, image, imageAlt, textBlocks }) => {
  const theme = useTheme();
  return (
    <SectionContainerGrid className="lpMastheadVariant__VariantTitleSubtitleImageThreeTextBlocks">
      <Grid
        className="lpMastheadVariant__title"
        item
        xs={12}
        marginBottom={theme.spacing(6)}
      >
        <Typography
          textAlign="center"
          sx={{ color: 'text.primary' }}
          variant="h1"
        >
          {title}
        </Typography>
      </Grid>
      {subtitle && (
        <Grid className="lpMastheadVariant__subtitle" maxWidth="80%">
          <Typography
            textAlign="center"
            sx={{ color: 'text.primary' }}
            variant="h2"
          >
            {subtitle}
          </Typography>
        </Grid>
      )}
      <Grid
        className="lpMastheadVariant__imageText"
        container
        item
        xs={12}
        spacing={{
          xs: theme.spacing(4),
          sm: theme.spacing(6),
        }}
        direction={{
          xs: 'column-reverse',
          sm: 'row',
        }}
      >
        <Grid className="lpMastheadVariant__textBlock[0]" item xs={12} sm={5}>
          <StyledType textBlock={textBlocks[0]} />
        </Grid>
        <Grid className="lpMastheadVariant__image" item xs={12} sm={7}>
          {image && <Image image={image} alt={imageAlt} />}
        </Grid>
      </Grid>

      <TwoColumnTextGrid>
        <Grid className="lpMastheadVariant__textBlock[1]" item xs={12} sm={6}>
          {textBlocks[1] && <RichText content={textBlocks[1].raw} />}
        </Grid>

        <Grid className="lpMastheadVariant__textBlock[2]" item xs={12} sm={6}>
          {textBlocks[2] && <RichText content={textBlocks[2].raw} />}
        </Grid>
      </TwoColumnTextGrid>
    </SectionContainerGrid>
  );
};

export default VariantOne;
