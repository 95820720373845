/**
 * Get all children of a particular type from an array of GraphCMS elements, or object containing children array of GraphCMS objects.
 * @param type Type of object to match.
 * @param graphCmsElements Object or array of objects representing HTML elements from GraphCMS query.
 */
const getChildrenOfType = (type: string, graphCmsElements: any): Array<any> => {
  let elements: Array<any> = [];

  // If we have an object of the appropriate type, push it to the array.
  if (
    typeof graphCmsElements === 'object' &&
    !Array.isArray(graphCmsElements) &&
    graphCmsElements !== null
  ) {
    if (graphCmsElements.children && graphCmsElements.children.length) {
      elements = elements.concat(
        getChildrenOfType(type, graphCmsElements.children),
      );
    }
    if (graphCmsElements.type === type) {
      // Delete child elements since they'll have already been found in previous statement.
      const returnObj = Object.create(graphCmsElements);
      delete returnObj.children;
      return elements.concat(returnObj);
    }
    if (type === 'text' && typeof graphCmsElements?.text === 'string') {
      elements.push(graphCmsElements.text);
      return elements;
    }
  }

  // If we have an array, recursively go through each array entry.
  if (Array.isArray(graphCmsElements)) {
    graphCmsElements.forEach((item) => {
      elements = elements.concat(getChildrenOfType(type, item));
    });
  }
  return elements;
};

export default getChildrenOfType;
