import React from 'react';
import { Grid, Typography } from '@mui/material';
import { RichText } from '@graphcms/rich-text-react-renderer';
import SectionContainerGrid from '../sectionContainerGrid';
import { GraphCmsRichText } from '../../GlobalState';

const VariantFour: React.FC<{
  title: string;
  textBlock: GraphCmsRichText;
}> = ({ title, textBlock }) => (
  <SectionContainerGrid className="lpMastheadVariant__variantFour">
    <Grid item className="lpMasthead__title" xs={12}>
      <Typography textAlign="center" variant="h1">
        {title}
      </Typography>
    </Grid>
    <Grid item className="lpMasthead__textBlock" xs={12}>
      <Typography
        variant="bodyLE"
        textAlign="center"
        component="div"
        style={{
          maxWidth: '720px',
        }}
      >
        {textBlock && <RichText content={textBlock.raw} />}
      </Typography>
    </Grid>
  </SectionContainerGrid>
);

export default VariantFour;
