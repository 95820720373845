import React from 'react';
import {
  Grid,
  Typography,
  useTheme,
  Box,
  useMediaQuery,
  styled,
} from '@mui/material';
import { RichText } from '@graphcms/rich-text-react-renderer';
import SectionContainerGrid from '../sectionContainerGrid';
import {
  GatsbyImageDataParent,
  ColorPickerData,
  GraphCmsRichText,
} from '../../GlobalState';
import Image from '../../Image';

export const alignmentList = ['right', 'left'] as const;

export type AlignmentOption = typeof alignmentList[number];

export type BackgroundImageType = {
  url: string;
};

const StyledTextGrid = styled(Grid)(
  ({ theme }) => `
    &&.lpOffsetBox__text { 
      position: relative;
      ${theme.breakpoints.only('xs')} {
        padding: ${theme.spacing(5)} ${theme.spacing(3)};
      }
      ${theme.breakpoints.up('sm')} {
        padding: ${theme.spacing(5)} ${theme.spacing(8)};
      }
    }
`,
);

const OffsetBox: React.FC<{
  title: string;
  textBlock: GraphCmsRichText;
  alignment: AlignmentOption;
  backgroundImage: BackgroundImageType;
  gradientStart: ColorPickerData;
  gradientStop: ColorPickerData;
  image?: GatsbyImageDataParent;
  imageAlt?: string;
}> = ({
  title,
  textBlock,
  alignment,
  backgroundImage,
  gradientStart,
  gradientStop,
  image,
  imageAlt,
}) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
  const isMobile = useMediaQuery(theme.breakpoints.only('xs'));
  return (
    <Grid
      className="lpOffSetBoxWrapper"
      container
      justifyContent={alignment === 'right' ? 'flex-end' : 'flex-start'}
      maxWidth={theme.breakpoints.values.xl}
      alignSelf="center"
    >
      <SectionContainerGrid
        className="lpOffsetBox"
        sx={{
          paddingLeft: 0,
          paddingRight: 0,
          paddingTop: theme.spacing(0),
          paddingBottom: theme.spacing(0),
          marginTop: 0,
        }}
        xs={12}
        md={11}
        style={{
          alignContent: alignment === 'right' ? 'flex-end' : 'flex-start',
        }}
      >
        <Grid
          className="lpOffsetBox__content"
          direction={alignment === 'right' ? 'row' : 'row-reverse'}
          // item
          container
          xs={12}
          md={10}
          sx={{
            backgroundPosition: alignment,
            backgroundSize: 'cover',
            backgroundImage: backgroundImage
              ? `url(${backgroundImage.url})`
              : 'transparent',
          }}
          style={{
            padding: 0,
            borderRadius:
              alignment === 'right' ? '10px 10px 0px 0px' : '0px 0px 10px 10px',
          }}
        >
          <StyledTextGrid
            className="lpOffsetBox__text"
            item
            xs={12}
            sm={6}
            md={5}
          >
            {title ? (
              <Grid xs={12} style={{ paddingTop: 0 }}>
                <Typography
                  textAlign="left"
                  component="h2"
                  variant={isDesktop ? 'h2' : 'h4'}
                  sx={{ zIndex: 1, position: 'relative' }}
                  style={{ marginBottom: theme.spacing(6) }}
                >
                  {title}
                </Typography>
              </Grid>
            ) : null}
            {isMobile ? (
              <Box
                component={Grid}
                item
                className="lpOffsetBox__image"
                xs={12}
                alignSelf="center"
                alignContent="center"
                justifyContent="center"
                sx={{ zIndex: 1, position: 'relative' }}
              >
                {image?.gatsbyImageData && imageAlt && (
                  <Image
                    image={image?.gatsbyImageData}
                    alt={imageAlt}
                    imgStyle={{ display: 'block', margin: '0 auto' }}
                    width="100%"
                    height="auto"
                  />
                )}
              </Box>
            ) : null}

            <Typography
              variant="body1"
              component="div"
              sx={{ zIndex: 1, position: 'relative' }}
            >
              {textBlock && <RichText content={textBlock.raw} />}
            </Typography>
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                background: `linear-gradient(rgba(${gradientStart?.rgba?.r}, ${gradientStart?.rgba?.g}, ${gradientStart?.rgba?.b}, 0.35), rgba(${gradientStop?.rgba?.r}, ${gradientStop?.rgba?.g}, ${gradientStop?.rgba?.b}, 0.35))`,
                opacity: 0.85,
                zIndex: 0,
                backdropFilter: 'blur(10px)',
              }}
            />
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                zIndex: 0,
                backdropFilter: 'blur(15px)',
              }}
            />
          </StyledTextGrid>
        </Grid>
      </SectionContainerGrid>
    </Grid>
  );
};

export default OffsetBox;
