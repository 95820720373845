import React from 'react';
import { styled } from '@mui/material';
import Grid from '@mui/material/Grid';
import Collapse from '@mui/material/Collapse';
import { RichText } from '@graphcms/rich-text-react-renderer';
import { RichTextContent } from '@graphcms/rich-text-types';
import key from 'weak-key';
import theme from '../theme';
import Button from './button';
import { Icon } from './icon';

export type Row = {
  title?: string;
  description?: string;
  specificationName?: string;
  specificationDescription?: { raw: RichTextContent };
  id?: string;
};

export type SpecTableProps = {
  title: string;
  rowsLimit?: number;
  rows: Row[];
  specMarginBottom?: number;
};

const CustomTableContainer = styled(Grid)`
  border: 1px solid ${theme.palette.greyscale.lightGrey};
  border-radius: 10px;
  margin-top: ${theme.spacing(4)};
  margin-bottom: ${theme.spacing(12)};
`;

const CustomTableCell = styled(Grid)`
  font-family: 'HCo Gotham SSm';
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5rem;
  a {
    color: #6acad8;
  }
`;

const CustomTableRow = styled(Grid)`
  border-bottom: 1px solid ${theme.palette.greyscale.lightGrey};
  padding: ${theme.spacing(2)};
`;

const Title = styled(CustomTableCell)`
  font-weight: 700;
  margin-bottom: ${theme.spacing(1)};
  @media (min-width: ${theme.breakpoints.values.sm}) {
    margin-bottom: 0;
  }
`;

const CustomButton = styled(Button)`
  color: ${theme.palette.cyan};
  padding: ${theme.spacing(2)} 0;
  width: 100%;

  &:hover,
  &:focus,
  &:active {
    color: ${theme.palette.cyan};
  }

  @media (max-width: ${theme.breakpoints.values.sm}px) {
    font-size: 12px;
  }
`;

const BottomRow = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TableRow: React.FC<{
  row: Row;
}> = ({ row }) => (
  <CustomTableRow
    key={row?.id || `${row?.title}-${row?.description}`}
    container
    item
    xs={12}
    role="row"
  >
    <Title item xs={12} sm={6} role="cell">
      {row?.specificationName || row?.title}
    </Title>
    {row?.specificationDescription?.raw && (
      <CustomTableCell item xs={12} sm={6} role="cell">
        <RichText content={row?.specificationDescription?.raw} />
      </CustomTableCell>
    )}
    {row?.description && (
      <CustomTableCell
        item
        xs={12}
        sm={6}
        dangerouslySetInnerHTML={{
          __html: row.description,
        }}
        role="cell"
      />
    )}
  </CustomTableRow>
);

const TableRows: React.FC<{
  rows: Row[];
  rowsLimit: number;
  expanded: boolean;
}> = ({ rows = [], rowsLimit = Infinity, expanded = false }) => (
  <>
    {rows.map((row: Row, index: number) => {
      // Render the rows visible inside the Collapse.
      if (expanded && index >= rowsLimit) {
        return <TableRow row={row} key={key(row)} />;
      }
      // Render the initial rows visible before expanding.
      if (!expanded && index < rowsLimit) {
        return <TableRow row={row} key={key(row)} />;
      }
      return null;
    })}
  </>
);

const SpecTable: React.FC<SpecTableProps> = ({
  rows = [],
  title = '',
  rowsLimit = Infinity,
  specMarginBottom,
}) => {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <CustomTableContainer
      container
      item
      xs={12}
      role="table"
      aria-label={title}
      aria-rowcount={rows.length}
      sx={{ marginBottom: specMarginBottom }}
    >
      <TableRows rows={rows} rowsLimit={rowsLimit} expanded={false} />
      {rows.length > rowsLimit ? (
        <>
          <Collapse
            in={expanded}
            timeout="auto"
            unmountOnExit
            sx={{ width: '100%' }}
          >
            <TableRows rows={rows} rowsLimit={rowsLimit} expanded />
          </Collapse>
          <BottomRow item xs={12}>
            {expanded ? (
              <CustomButton
                variant="ghost"
                text="View Less"
                startIcon={<Icon iconName="arrow-up" />}
                onClick={() => handleExpandClick()}
              />
            ) : (
              <CustomButton
                variant="ghost"
                text="View More"
                startIcon={<Icon iconName="arrow-down" />}
                onClick={() => handleExpandClick()}
              />
            )}
          </BottomRow>
        </>
      ) : null}
    </CustomTableContainer>
  );
};

export default SpecTable;
