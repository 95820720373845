import React, {useContext} from 'react';
import { navigate } from 'gatsby';
import { Product, AppAction, useDispatch } from '../GlobalState';
import { AddToCartButton } from '../addToCartButton';
import Button from '../button';
import ctaClickHandler from './ctaClickHandler';
import { CartContext } from '../../context/CartContext';

const LandingPageCtaButton: React.FC<{
  ctaText?: string;
  sx: React.CSSProperties;
  color?: 'marigold' | 'white' | 'cyan';
  product: Product;
  ctaAction?: AppAction;
  ctaLink?: string;
}> = ({ ctaText, sx, color = 'marigold', product, ctaAction, ctaLink }) => {
  const dispatch = useDispatch();
  const {myCart, setShowCart} = useContext(CartContext);

  const hasText = ctaText && ctaText.length > 0;
  if (!hasText) {
    return null;
  }

  return ctaAction === 'addToCart' ? (
    <AddToCartButton
      sx={sx}
      variant="primary"
      color={color}
      text={ctaText}
      product={product}
      addToCartAction={() => {
        dispatch({
          type: 'CART_ADD',
          product: { ...product, quantity: 1 },
        });
        // navigate('/cart');
        setShowCart(true);
      }}
    />
  ) : (
    <Button
      sx={sx}
      href={ctaLink}
      text={ctaText}
      onClick={(e) => ctaClickHandler(e, ctaAction, ctaLink)}
    />
  );
};

export default LandingPageCtaButton;
