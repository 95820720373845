import React from 'react';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import Box from '@mui/material/Box';

export type ImageProps = {
  image?: IGatsbyImageData;
  alt: string;
  width?: number | string;
  height?: number | string;
  id?: string;
  imgStyle?: React.CSSProperties;
  sx?: React.CSSProperties;
};

const Image: React.FC<ImageProps> = ({
  image,
  alt,
  width,
  height,
  imgStyle,
  sx = {},
  ...props
}) => (
  <Box
    sx={{
      maxWidth: width,
      maxHeight: height,
      ...sx,
    }}
    {...props}
  >
    {image && (
      <GatsbyImage
        image={image}
        alt={alt}
        imgStyle={imgStyle}
        style={{
          transform: 'translateZ(0)',
        }}
      />
    )}
  </Box>
);

export default React.memo(Image);
