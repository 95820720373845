import React from 'react';
import { Grid, Typography } from '@mui/material';
import { RichText } from '@graphcms/rich-text-react-renderer';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import { GraphCmsRichText } from '../../GlobalState';
import SectionContainerGrid from '../sectionContainerGrid';
import TwoColumnTextGrid from '../twoColumnTextGrid';
import Image from '../../Image';

const VariantTwo: React.FC<{
  title: string;
  image: IGatsbyImageData;
  imageAlt: string;
  textBlocks: Array<GraphCmsRichText>;
}> = ({ title, image, imageAlt, textBlocks }) => (
  <SectionContainerGrid className="lpMastheadVariant__VariantTitleImageTwoTextBlocks">
    <Grid className="lpMastheadVariant__title" item xs={12}>
      <Typography
        textAlign="center"
        sx={{ color: 'text.primary' }}
        variant="h1"
      >
        {title}
      </Typography>
    </Grid>

    <Grid className="lpMastheadVariant__imageGrid" item container xs={12}>
      <Grid className="lpMastheadVariant__spacer" item xs={2} />
      <Grid className="lpMastheadVariant__image" item xs={8}>
        {image && <Image image={image} alt={imageAlt} />}
      </Grid>
      <Grid className="lpMastheadVariant__spacer" item xs={2} />
    </Grid>

    <TwoColumnTextGrid>
      <Grid className="lpMastheadVariant__textBlock[1]" item xs={12} sm={6}>
        <Typography sx={{ color: 'text.primary' }} variant="body1">
          {textBlocks[0] && <RichText content={textBlocks[0].raw} />}
        </Typography>
      </Grid>

      <Grid className="lpMastheadVariant__textBlock[2]" item xs={12} sm={6}>
        <Typography sx={{ color: 'text.primary' }} variant="body1">
          {textBlocks[1] && <RichText content={textBlocks[1].raw} />}
        </Typography>
      </Grid>
    </TwoColumnTextGrid>
  </SectionContainerGrid>
);

export default VariantTwo;
