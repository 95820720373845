import React from 'react';
import {
  Grid,
  Typography,
  useTheme,
  List,
  ListItem,
  useMediaQuery,
} from '@mui/material';
import { ColorPickerData } from '../../GlobalState';
import getSlug from '../../../utils/get-page-slug';

const TextList: React.FC<{
  title: string;
  listItems: string[];
  gradientStart: ColorPickerData;
  gradientStop: ColorPickerData;
}> = ({ title, listItems, gradientStart, gradientStop }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <section
      style={{
        background: `linear-gradient(rgba(${gradientStart?.rgba?.r}, ${gradientStart?.rgba?.g}, ${gradientStart?.rgba?.b}, 0.35), rgba(${gradientStop?.rgba?.r}, ${gradientStop?.rgba?.g}, ${gradientStop?.rgba?.b}, 0.35))`,
      }}
    >
      <Grid
        className="lpTextList"
        container
        justifyContent="center"
        alignItems="center"
        direction="column"
        maxWidth="1024px"
        margin="0 auto"
        padding={{
          xs: theme.spacing(4),
          sm: theme.spacing(6),
        }}
      >
        <Grid item marginBottom={theme.spacing(3)}>
          <Typography
            className="lpTextList__title"
            variant={isMobile ? 'h4' : 'h2'}
            textAlign="center"
          >
            {title}
          </Typography>
        </Grid>
        <Grid className="lpTextList__list">
          <List className="lpTextList__list">
            {listItems.map((item) => (
              <ListItem
                className="lpTextList__listItem"
                // eslint-disable-next-line react/no-array-index-key
                key={getSlug(item)}
                style={{ justifyContent: 'center' }}
              >
                <Typography
                  variant={isMobile ? 'bodyS' : 'bodyL'}
                  textAlign="center"
                  component="p"
                >
                  {item}
                </Typography>
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
    </section>
  );
};

export default TextList;
