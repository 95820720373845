import React from 'react';
import { useTheme, Grid, SxProps, Theme } from '@mui/material';

/**
 * Wrapping container for variants.
 */
const CardContainer: React.FC<{
  className: string;
  children: React.ReactNode;
  sx?: SxProps<Theme>;
  style?: React.CSSProperties;
}> = ({ className, children, sx, style }) => {
  const theme = useTheme();
  return (
    <Grid
      className={className || 'cardContainer'}
      item
      container
      direction="column"
      min-width="100%"
      bgcolor={{
        xs: 'transparent',
        sm: theme.palette.transparent.cyan12,
      }}
      padding={{
        xs: `0 ${theme.spacing(3)}`,
        sm: theme.spacing(3),
        md: theme.spacing(4),
      }}
      height={{
        xs: 'auto',
        sm: 'auto',
        md: 'auto',
      }}
      flexWrap="nowrap"
      sx={sx}
      style={style}
    >
      {children}
    </Grid>
  );
};

export default CardContainer;
