import React from 'react';
import { Grid, Typography, useTheme, useMediaQuery } from '@mui/material';
import { GatsbyImageDataParent } from '../../GlobalState';
import Image from '../../Image';
import CardContainer from '../cardContainer';
import useShowModal from '../../../hooks/useShowModal';
import VideoModal from '../../video-modal';

const VideoCard: React.FC<{
  title: string;
  bodyText: string;
  previewImage: GatsbyImageDataParent;
  videoId: string;
}> = ({ title, bodyText, previewImage, videoId }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const { showModal, toggleModal } = useShowModal();
  return (
    <CardContainer className="lpVideoCard">
      <Grid item paddingLeft={isMobile ? '0px' : undefined} order={1}>
        <Typography
          component="h3"
          variant={isDesktop ? 'h3' : 'h4'}
          textAlign={{
            xs: 'left',
            sm: 'center',
          }}
          margin={{
            xs: '0 0 0 0',
            sm: `0 0 ${theme.spacing(3)} 0`,
          }}
        >
          {title}
        </Typography>
      </Grid>

      <Grid
        className="lpVideoCard__bodyText"
        item
        marginBottom={{
          xs: theme.spacing(3),
          sm: 0,
        }}
        order={{
          xs: 2,
          sm: 3,
        }}
      >
        <Typography component="p" variant={isMobile ? 'bodyS' : 'bodyS'}>
          {bodyText}
        </Typography>
      </Grid>

      <Grid
        className="lpVideoCard__image"
        item
        sx={{
          maxWidth: '100vw',
          height: 'auto',
        }}
        order={{
          xs: 3,
          sm: 2,
        }}
        margin={{
          xs: `0 -${theme.spacing(3)}`,
          sm: `0 0 ${theme.spacing(3)} 0`,
        }}
      >
        {previewImage && (
          <button
            style={{ border: 0, padding: 0, cursor: 'pointer' }}
            type="button"
            onClick={() => toggleModal()}
          >
            <Image
              image={previewImage.gatsbyImageData}
              alt={`Open ${title} video in a modal.`}
            />
          </button>
        )}
      </Grid>
      <VideoModal
        open={showModal}
        onClose={() => toggleModal()}
        videoId={videoId}
        title={title}
      />
    </CardContainer>
  );
};

export default VideoCard;
