import React from 'react';
import { Grid, Typography, useTheme, styled } from '@mui/material';
import { RichText } from '@graphcms/rich-text-react-renderer';
import { GraphCmsRichText } from '../GlobalState';
import { isEmpty } from '../../utils/isRichTextEmpty';

const FootnoteGridContainer = styled(Grid)`
  width: 100%;
  p:last-child {
    padding-bottom: ${({ theme }) => theme.spacing(4)};
    border-bottom: 1px solid ${({ theme }) => theme.palette.greyscale['04']};
  }
`;

type FootnoteProps = {
  footnotes: GraphCmsRichText;
};

const Footnotes: React.FC<FootnoteProps> = ({ footnotes }) => {
  const theme = useTheme();

  return footnotes?.raw && !isEmpty(footnotes?.raw) ? (
    <FootnoteGridContainer
      className="lpFootnotes"
      xs={12}
      marginTop={{
        xs: theme.spacing(10),
      }}
    >
      <Typography variant="bodyXS">
        <RichText content={footnotes.raw} />
      </Typography>
    </FootnoteGridContainer>
  ) : null;
};

export default Footnotes;
