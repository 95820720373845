import React from 'react';
import { Grid, Typography, useTheme, useMediaQuery } from '@mui/material';
import { AppAction } from '../../GlobalState';
import CardContainer from '../cardContainer';
import Button from '../../button';
import clickHandler from '../ctaClickHandler';

const CtaCard: React.FC<{
  title: string;
  bodyText: string;
  ctaText: string;
  ctaLink: string;
  ctaAction: AppAction;
}> = ({ title, bodyText, ctaText, ctaLink, ctaAction }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <CardContainer className="lpCtaCard">
      <Grid item container margin="auto">
        <Grid
          item
          className="lpCtaCard__title"
          margin={{
            xs: `0 0 ${theme.spacing(1)} 0`,
            sm: `0 0 ${theme.spacing(3)} 0`,
          }}
          textAlign={{
            xs: 'left',
            sm: 'center',
          }}
          width="100%"
        >
          <Typography
            component="h3"
            variant="h3"
            textAlign={{
              xs: 'left',
              sm: 'center',
            }}
          >
            {title}
          </Typography>
        </Grid>

        <Grid
          item
          className="lpCtaCard__bodyText"
          margin={{
            xs: `0 0 ${theme.spacing(5)} 0`,
          }}
          textAlign={{
            xs: 'left',
            sm: 'center',
          }}
        >
          <Typography component="p" variant="bodyL">
            {bodyText}
          </Typography>
        </Grid>

        <Grid item className="lpCtaCard__cta" textAlign="center" width="100%">
          <Button
            text={ctaText}
            color="white"
            variant="outline"
            fullWidth={isMobile}
            onClick={(e) => clickHandler(e, ctaAction, ctaLink)}
            sx={{
              padding: '6px 65px 6px 65px',
            }}
          />
        </Grid>
      </Grid>
    </CardContainer>
  );
};

export default CtaCard;
