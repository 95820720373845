import React from 'react';
import {
  Grid,
  Typography,
  useTheme,
  useMediaQuery,
  styled,
} from '@mui/material';
import { RichText } from '@graphcms/rich-text-react-renderer';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import { GraphCmsRichText, MastheadVariants } from '../../GlobalState';
import SectionContainerGrid from '../sectionContainerGrid';
import TwoColumnTextGrid from '../twoColumnTextGrid';
import Image from '../../Image';

const ImageGrid = styled(Grid)(
  ({ theme }) => `
    &&.lpMastheadVariant__imageGrid {
      ${theme.breakpoints.only('xs')} {
        margin-bottom: ${theme.spacing(7)};
      }
      ${theme.breakpoints.only('sm')} {
        margin-bottom: ${theme.spacing(5)};
      }
      ${theme.breakpoints.up('lg')} {
        margin-bottom: ${theme.spacing(3)};
      }
    }
`,
);

const VariantTwo: React.FC<{
  title: string;
  subtitle: string | undefined;
  image: IGatsbyImageData;
  imageAlt: string;
  textBlocks: Array<GraphCmsRichText>;
  layout: MastheadVariants;
}> = ({ title, subtitle, image, imageAlt, textBlocks, layout }) => {
  const theme = useTheme();
  const titleFirst = layout === 'Title_First';
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
  return (
    <SectionContainerGrid className="mastheadContent">
      <Grid
        className="lpMastheadVariant__title"
        container
        xs={12}
        order={titleFirst ? 1 : 2}
        direction={titleFirst ? 'column-reverse' : 'column'}
      >
        {title ? (
          <Grid
            margin={
              titleFirst
                ? `0 0 ${theme.spacing(5)} 0`
                : `0 0 ${theme.spacing(2)} 0`
            }
          >
            <Typography
              textAlign="center"
              sx={{ color: 'text.primary' }}
              component="h1"
              variant={isDesktop ? 'h1' : 'h3'}
            >
              {title}
            </Typography>
          </Grid>
        ) : null}

        {subtitle ? (
          <Grid
            margin={
              titleFirst
                ? `0 0 ${theme.spacing(2)} 0`
                : `0 0 ${theme.spacing(5)} 0`
            }
          >
            <Typography
              textAlign="center"
              sx={{ color: 'text.primary' }}
              component="h2"
              variant={isDesktop ? 'h2' : 'h4'}
            >
              {subtitle}
            </Typography>
          </Grid>
        ) : null}
      </Grid>

      <ImageGrid
        className="lpMastheadVariant__imageGrid"
        container
        xs={12}
        order={layout === 'Title_First' ? 2 : 1}
        justifyContent="center"
        direction="row"
        style={{
          paddingTop: 0,
          paddingLeft: 0,
        }}
      >
        <Grid className="lpMastheadVariant__spacer" xs={0} md={2} />
        <Grid className="lpMastheadVariant__image" xs={12} md={8}>
          {image && (
            <Image
              image={image}
              alt={imageAlt}
              display="flex"
              justifyContent="center"
            />
          )}
        </Grid>
        <Grid className="lpMastheadVariant__spacer" xs={0} md={2} />
      </ImageGrid>

      {textBlocks?.length && textBlocks.length === 2 ? (
        <TwoColumnTextGrid order={3}>
          <Grid
            className="lpMastheadVariant__textBlock[0] textBlock"
            xs={12}
            sm={6}
            item
          >
            <Typography
              sx={{ color: 'text.primary' }}
              variant="body1"
              component="div"
            >
              {textBlocks[0] && <RichText content={textBlocks[0].raw} />}
            </Typography>
          </Grid>

          <Grid
            className="lpMastheadVariant__textBlock[1] textBlock"
            xs={12}
            sm={6}
            item
          >
            <Typography
              sx={{ color: 'text.primary' }}
              variant="body1"
              component="div"
            >
              {textBlocks[1] && <RichText content={textBlocks[1].raw} />}
            </Typography>
          </Grid>
        </TwoColumnTextGrid>
      ) : null}
    </SectionContainerGrid>
  );
};

export default VariantTwo;
