import React from 'react';
import { Grid, useTheme } from '@mui/material';
import SectionContainerGrid from '../sectionContainerGrid';
import TextAndImageContent from './textAndImageContent';
import Footnotes from '../Footnotes';
import {
  GatsbyImageDataParent,
  ColorPickerData,
  AppAction,
  GraphCmsRichText,
  Product,
} from '../../GlobalState';

export const alignmentList = ['right', 'left'] as const;

export type AlignmentOption = typeof alignmentList[number];

const TextAndImageSection: React.FC<{
  title: string;
  subtitle?: string;
  textBlocks: Array<GraphCmsRichText>;
  image: GatsbyImageDataParent;
  mobileImage: GatsbyImageDataParent;
  imageAlt: string;
  imageColumn: AlignmentOption;
  ctaText?: string;
  ctaLink?: string;
  ctaAction?: AppAction;
  gradientColor: ColorPickerData;
  product: Product;
  footnotes: GraphCmsRichText;
}> = ({
  title,
  subtitle,
  textBlocks,
  image,
  mobileImage,
  imageAlt,
  imageColumn,
  ctaText,
  ctaLink,
  ctaAction,
  gradientColor,
  product,
  footnotes,
}) => {
  const { r, g, b } = gradientColor?.rgba || {};
  const theme = useTheme();
  return (
    <Grid
      className={
        // eslint-disable-next-line prefer-template
        'lpTextAndImage' +
        (!gradientColor || gradientColor?.hex === '#000000'
          ? ''
          : ' lpTextAndImage__hasGradient')
      }
      container
      justifyContent="center"
      alignItems="center"
      sx={{
        background: gradientColor?.rgba
          ? `linear-gradient(rgba(${r},${g},${b},0.4), rgba(${r},${g},${b},0.15))`
          : theme.palette.greyscale.black,
      }}
    >
      <SectionContainerGrid className="lpTextAndImage" direction="row">
        <TextAndImageContent
          title={title}
          subtitle={subtitle}
          textBlock={textBlocks?.[0]}
          image={image?.gatsbyImageData}
          mobileImage={mobileImage?.gatsbyImageData}
          imageAlt={imageAlt}
          imageColumn={imageColumn}
          ctaText={ctaText}
          ctaLink={ctaLink}
          ctaAction={ctaAction}
          product={product}
        />
        {footnotes && footnotes.raw && <Footnotes footnotes={footnotes} />}
      </SectionContainerGrid>
    </Grid>
  );
};

export default TextAndImageSection;
