/* eslint-disable no-nested-ternary */
import React from 'react';
import {
  Grid,
  Typography,
  useTheme,
  useMediaQuery,
  styled,
} from '@mui/material';
import StyledType from '../styledType';
import { Product, AppAction, GraphCmsRichText } from '../../GlobalState';
import LandingPageCtaButton from '../LandingPageCtaButton';
import OneColumnTextGrid from '../oneColumnTextGrid';
import getChildrenOfType from '../../../utils/get-children-of-type';

const StyledGrid = styled(Grid)(
  ({ theme }) => `
    &&.lpTextOnlyVariant__oneBlock { 
      ${theme.breakpoints.only('xs')} {
        padding-top: ${theme.spacing(3)};
        padding-bottom: ${theme.spacing(3)};
        width: 100%;
        margin-left: 0;
      }
      ${theme.breakpoints.only('sm')} {
        width: 100%;
        margin-left: 0;
        margin-top: 0;
      }
      ${theme.breakpoints.up('md')} {
        width: 100%;
        margin-left: 0;
        margin-top: 0;
      }
    }
`,
);

const OneTextBlockVariant: React.FC<{
  title: string;
  ctaText?: string;
  ctaLink?: string;
  ctaAction?: AppAction;
  textBlocks: Array<GraphCmsRichText>;
  product: Product;
  subtitle?: string;
}> = ({
  title,
  ctaText,
  ctaLink,
  ctaAction,
  textBlocks,
  product,
  subtitle,
}) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
  const tableList = getChildrenOfType('table', textBlocks?.[0]?.raw);
  return (
    <StyledGrid
      container
      direction="column"
      className="lpTextOnlyVariant__oneBlock"
      spacing={theme.spacing(6)}
      style={{
        [theme.breakpoints.only('xs')]: {
          paddingTop: theme.spacing(3),
          paddingBottom: theme.spacing(3),
        },
      }}
    >
      {title ? (
        <Grid xs={12} sm={6} style={{ paddingTop: 0 }}>
          <Typography
            textAlign="left"
            component="h2"
            variant={isDesktop ? 'h2' : 'h4'}
            style={{
              marginBottom: subtitle ? theme.spacing(1) : 0,
            }}
          >
            {title}
          </Typography>
        </Grid>
      ) : null}

      {subtitle ? (
        <Grid xs={12} sm={6}>
          <Typography
            textAlign="left"
            component="h3"
            variant={isDesktop ? 'h3' : 'h5'}
          >
            {subtitle}
          </Typography>
        </Grid>
      ) : null}

      <OneColumnTextGrid container fullWidth={tableList.length > 0}>
        {textBlocks.length && textBlocks[0] && (
          <StyledType
            textBlock={textBlocks[0]}
            textAlign="left"
            component="div"
          />
        )}
        <LandingPageCtaButton
          sx={{ marginTop: theme.spacing(6) }}
          ctaText={ctaText}
          ctaLink={ctaLink}
          ctaAction={ctaAction}
          product={product}
        />
      </OneColumnTextGrid>
    </StyledGrid>
  );
};

export default OneTextBlockVariant;
