import React from 'react';
import { Grid, Typography, useTheme, useMediaQuery } from '@mui/material';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import StyledType from '../styledType';
import { Product, AppAction, GraphCmsRichText } from '../../GlobalState';
import LandingPageCtaButton from '../LandingPageCtaButton';
import TwoColumnTextGrid from '../twoColumnTextGrid';
import Image from '../../Image';

/**
 * Get grid direction from isMobile and imageColumn.
 * @param isMobile boolean
 * @param imageColumn right or left
 * @returns Grid direction
 */
const getDirection = (
  isMobile: boolean,
  imageColumn: 'right' | 'left',
): 'column-reverse' | 'row-reverse' | 'row' => {
  if (isMobile) {
    return 'column-reverse';
  }
  if (imageColumn === 'left') {
    return 'row-reverse';
  }
  return 'row';
};

const TextAndImageContent: React.FC<{
  title: string;
  ctaText?: string;
  ctaLink?: string;
  ctaAction?: AppAction;
  textBlock: GraphCmsRichText;
  product: Product;
  subtitle?: string;
  imageColumn: 'left' | 'right';
  image: IGatsbyImageData;
  mobileImage: IGatsbyImageData;
  imageAlt: string;
}> = ({
  title,
  subtitle,
  ctaText,
  ctaLink,
  ctaAction,
  textBlock,
  product,
  imageColumn,
  image,
  mobileImage,
  imageAlt,
}) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
  const isMobile = useMediaQuery(theme.breakpoints.only('xs'));
  return (
    <Grid
      container
      item
      direction="column"
      className="textAndImageContent"
      spacing={theme.spacing(6)}
    >
      <TwoColumnTextGrid
        direction={getDirection(isMobile, imageColumn)}
        style={{ paddingTop: 0 }}
      >
        <Grid
          item
          xs={12}
          sm={6}
          className={`${getDirection(isMobile, imageColumn)}`}
        >
          {title ? (
            <Typography
              textAlign="left"
              component="h2"
              variant={isDesktop ? 'h2' : 'h4'}
              marginBottom={{
                xs: theme.spacing(1),
                sm: theme.spacing(subtitle ? 2 : 4),
              }}
            >
              {title}
            </Typography>
          ) : null}

          {subtitle ? (
            <Typography
              textAlign="left"
              component="h3"
              variant={isDesktop ? 'h3' : 'h5'}
              marginBottom={{
                xs: theme.spacing(2),
                sm: theme.spacing(2),
              }}
            >
              {subtitle}
            </Typography>
          ) : null}

          {isMobile && (mobileImage || image) && (
            <Image
              image={mobileImage || image}
              alt={imageAlt}
              width="100%"
              height="100%"
              sx={{
                width: '100%',
                height: 'auto',
                marginBottom: theme.spacing(2),
              }}
              display="flex"
            />
          )}

          <StyledType textBlock={textBlock} textAlign="left" />

          {isDesktop ? (
            <LandingPageCtaButton
              sx={{ marginTop: theme.spacing(6) }}
              ctaText={ctaText}
              ctaLink={ctaLink}
              ctaAction={ctaAction}
              product={product}
            />
          ) : null}
        </Grid>
        {!isMobile ? (
          <Grid
            item
            xs={12}
            sm={6}
            className={`imageContainer ${getDirection(isMobile, imageColumn)}`}
            container
            direction="column"
            justifyContent="center"
          >
            {image && (
              <Image
                image={image}
                alt={imageAlt}
                width="100%"
                height="100%"
                sx={{
                  width: '100%',
                  height: 'auto',
                }}
                display="flex"
              />
            )}
          </Grid>
        ) : null}
      </TwoColumnTextGrid>
      {isMobile ? (
        <LandingPageCtaButton
          sx={{ marginTop: theme.spacing(6) }}
          ctaText={ctaText}
          ctaLink={ctaLink}
          ctaAction={ctaAction}
          product={product}
        />
      ) : null}
    </Grid>
  );
};

export default TextAndImageContent;
