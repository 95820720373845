import React from 'react';
import { Grid, useTheme } from '@mui/material';
import {
  ColorPickerData,
  GraphCmsRichText,
  AppAction,
  Product,
} from '../../GlobalState';
import SectionContainerGrid from '../sectionContainerGrid';
import CenteredTextVariant from '../textOnly/CenteredTextVariant';

export const alignmentList = ['right', 'left'] as const;

export type AlignmentOption = typeof alignmentList[number];

const CenteredTextSection: React.FC<{
  multiLineTitle: string;
  multiLineSubtitle: string;
  bodyRichText: GraphCmsRichText;
  ctaText?: string;
  ctaLink?: string;
  ctaAction?: AppAction;
  gradientColor: ColorPickerData;
  product: Product;
}> = ({
  multiLineTitle,
  multiLineSubtitle,
  ctaText,
  ctaLink,
  ctaAction,
  gradientColor,
  bodyRichText,
  product,
}) => {
  const { r, g, b } = gradientColor?.rgba || {};
  const theme = useTheme();
  return (
    <Grid
      className="lpCenteredText__wrap"
      container
      justifyContent="center"
      alignItems="center"
      sx={{
        background: gradientColor?.rgba
          ? `linear-gradient(rgba(${r},${g},${b},0.4), rgba(${r},${g},${b},0.15))`
          : theme.palette.greyscale.black,
      }}
    >
      <SectionContainerGrid
        className="lpCenteredText__SectionContainerGrid"
        direction="column"
        sx={{
          [theme.breakpoints.down('md')]: {
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(3),
          },
        }}
      >
        <CenteredTextVariant
          title={multiLineTitle}
          subtitle={multiLineSubtitle}
          ctaText={ctaText}
          ctaLink={ctaLink}
          ctaAction={ctaAction}
          bodyText={bodyRichText}
          product={product}
        />
      </SectionContainerGrid>
    </Grid>
  );
};

export default CenteredTextSection;
