import React from 'react';
import { Grid, useTheme } from '@mui/material';
import {
  ColorPickerData,
  Product,
  GraphCmsRichText,
  AppAction,
} from '../../GlobalState';
import SectionContainerGrid from '../sectionContainerGrid';
import OneTextBlockVariant from './OneTextBlockVariant';
import TwoTextBlocksVariant from './TwoTextBlocksVariant';
import CenteredTextVariant from './CenteredTextVariant';
import Footnotes from '../Footnotes';

export const alignmentList = ['right', 'left'] as const;

export type AlignmentOption = typeof alignmentList[number];

const TextOnlySection: React.FC<{
  title: string;
  subtitle: string;
  textBlocks: Array<GraphCmsRichText>;
  ctaText?: string;
  ctaLink?: string;
  ctaAction?: AppAction;
  gradientColor: ColorPickerData;
  product: Product;
  footnotes: GraphCmsRichText;
}> = ({
  title,
  subtitle,
  textBlocks,
  ctaText,
  ctaLink,
  ctaAction,
  gradientColor,
  product,
  footnotes,
}) => {
  const { r, g, b } = gradientColor?.rgba || {};
  const theme = useTheme();
  return (
    <Grid
      className="lpTextOnly__wrap"
      container
      justifyContent="center"
      alignItems="center"
      sx={{
        background: gradientColor?.rgba
          ? `linear-gradient(rgba(${r},${g},${b},0.4), rgba(${r},${g},${b},0.15))`
          : theme.palette.greyscale.black,
      }}
    >
      <SectionContainerGrid
        className="lpTextOnly__SectionContainerGrid"
        direction="column"
        sx={{
          [theme.breakpoints.down('md')]: {
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(3),
          },
        }}
      >
        {textBlocks?.length === 0 && (
          <CenteredTextVariant
            title={title}
            subtitle={subtitle}
            ctaText={ctaText}
            ctaLink={ctaLink}
            ctaAction={ctaAction}
            product={product}
          />
        )}
        {textBlocks?.length === 1 && (
          <OneTextBlockVariant
            title={title}
            subtitle={subtitle}
            textBlocks={textBlocks}
            ctaText={ctaText}
            ctaLink={ctaLink}
            ctaAction={ctaAction}
            product={product}
          />
        )}
        {textBlocks?.length > 1 && (
          <TwoTextBlocksVariant
            title={title}
            subtitle={subtitle}
            textBlocks={textBlocks}
            ctaText={ctaText}
            ctaLink={ctaLink}
            ctaAction={ctaAction}
            product={product}
          />
        )}
        {footnotes && footnotes.raw && <Footnotes footnotes={footnotes} />}
      </SectionContainerGrid>
    </Grid>
  );
};

export default TextOnlySection;
