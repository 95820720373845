import React from 'react';
import { useTheme, Grid, styled } from '@mui/material';

const StyledGrid = styled(Grid)(
  ({ theme }) => `
    margin: 0!important;
    flex-grow: 1;
    ${theme.breakpoints.only('xs')} {
      padding-top: ${theme.spacing(2)}!important;
    }
    ${theme.breakpoints.only('sm')} {
      padding-top: ${theme.spacing(3)}!important;
      margin-left: 0; /* Overriding something from somewhere. */
      margin-top: 0;
    }
    ${theme.breakpoints.only('md')} {
      padding-top: ${theme.spacing(5)}!important;
    }
    ${theme.breakpoints.up('lg')} {
      margin-left: 0; /* Overriding something from somewhere. */
      margin-top: 0;
      width: 100%;
      padding-top: ${theme.spacing(3)}!important;
    }
`,
);

/**
 * Container grid for two equally-spaced columns.
 * @TODO: using the xs, sm, md props here result in a console warning for using them without an item
 * prop, however adding the item prop throws off the layout and results in it's own set of console
 * warnings. It also appears that using the props with the item prop does not work, the grid item
 * no longer limit's itself to the number of columns defined in the props.
 */
const OneColumnTextGrid: React.FC<{
  children: React.ReactNode;
  order?: number;
  container?: boolean;
  fullWidth?: boolean;
}> = ({ children, order, container, fullWidth }) => {
  const theme = useTheme();
  return (
    <StyledGrid
      className="oneColumnTextGrid"
      container={container}
      xs={12}
      sm={fullWidth ? 12 : 10}
      md={fullWidth ? 12 : 8}
      spacing={{
        xs: theme.spacing(0.125),
        sm: theme.spacing(3),
        md: theme.spacing(4),
      }}
      direction={{
        xs: 'column',
        sm: 'row',
      }}
      order={order || 0}
    >
      {children}
    </StyledGrid>
  );
};

export default OneColumnTextGrid;
