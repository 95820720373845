import { SyntheticEvent } from 'react';
import { sanitizeUrl } from '@braintree/sanitize-url';
import { AppAction } from '../GlobalState';
import { HPID_LOGIN_URL } from '../../settings';

/**
 * Open link, if it exists.
 * @param link - Link to open
 */
const openLink = (link?: string): void => {
  if (link) {
    window.location.assign(sanitizeUrl(link));
  }
};

/**
 * Click handler for CTA buttons on landing pages.
 * @param e - The event that triggered the function
 * @param ctaAction - Action to take when button is clicked
 * @param ctaLink - Link to open
 * @returns false to prevent default behavior
 */
const clickHandler = (
  e: SyntheticEvent,
  ctaAction?: AppAction,
  ctaLink?: string,
): false => {
  e.preventDefault();
  e.stopPropagation();
  switch (ctaAction) {
    case 'openLink':
      openLink(ctaLink);
      break;
    case 'login':
      openLink(HPID_LOGIN_URL);
      break;
    default:
      openLink(ctaLink);
  }
  return false;
};

export default clickHandler;
