import React from 'react';
import { RichText } from '@graphcms/rich-text-react-renderer';
import { Typography, styled, useTheme } from '@mui/material';
import { GraphCmsRichText } from '../GlobalState';
import Table from '../Table';

const Type = styled(Typography)`
  min-width: 100%;
  > :last-child {
    margin-bottom: 0;
  }
  > img:last-of-type {
    margin-top: 2rem;
  }
`;

const StyledType: React.FC<{
  textBlock: GraphCmsRichText;
  textAlign: 'left' | 'right' | 'center';
}> = ({ textBlock, textAlign = 'left' }) => {
  const theme = useTheme();
  return (
    <Type variant="bodyL" textAlign={textAlign} component="div">
      {textBlock && (
        <RichText
          content={textBlock.raw}
          renderers={{
            p: ({ children }) => {
              if (children?.props?.content[0].bold === true) {
                return (
                  <p
                    style={{
                      marginTop: '0',
                      fontSize: '16px',
                      fontWeight: '400',
                      lineHeight: '24px',
                    }}
                  >
                    {children}
                  </p>
                );
              }
              return (
                <p
                  style={{
                    marginTop: '0',
                    fontSize: '16px',
                    fontWeight: '400',
                    lineHeight: '24px',
                  }}
                >
                  {children}
                </p>
              );
            },
            bold: ({ children }) => (
              <b
                style={{
                  fontSize: '18px',
                  fontWeight: '700',
                  lineHeight: '28px',
                }}
              >
                {children}
              </b>
            ),
            table: ({ children }) => (
              <Table customMobileLayout content={children?.props?.content} />
            ),
          }}
        />
      )}
    </Type>
  );
};

export default StyledType;
