/**
 * Copied from GraphCMS's code. Unfortunately, it's not exposed as a public API.
 * https://github.com/GraphCMS/rich-text/blob/c29fbee38c04ef121dbefcdb0475b93345be5bf7/packages/types/src/util/isEmpty.ts
 */
import { ElementNode, Text } from '@graphcms/rich-text-types';

export function isElement(node: Node): node is ElementNode {
  return (node as ElementNode).children !== undefined;
}

export function isText(node: Node): node is Text {
  return (node as Text).text !== undefined;
}

export function isEmpty({
  children,
}: {
  children: (ElementNode | Text)[];
}): boolean {
  // Checks if the children array has more than one element.
  // It may have a link inside, that's why we need to check this condition.
  if (children.length) {
    const hasText = children.filter(function f(child): boolean | number {
      if (isText(child) && child.text !== '') {
        return true;
      }

      if (isElement(child)) {
        return (child.children = child.children.filter(f)).length;
      }

      return false;
    });

    return !(hasText.length > 0);
  }
  if (children[0].text === '') return true;

  return false;
}
