/* eslint-disable no-underscore-dangle */
import React, { ReactNode } from 'react';
import { Grid, useTheme, styled, useMediaQuery } from '@mui/material';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Masthead from '../components/landing-page/masthead';
import OffsetBox from '../components/landing-page/offsetBox';
import TextAndImageSection from '../components/landing-page/textAndImage';
import TextOnlySection from '../components/landing-page/textOnly';
import CenteredTextSection from '../components/landing-page/centeredText';
import TextList from '../components/landing-page/textList';
import VideoCard from '../components/landing-page/videoCard';
import CtaCard from '../components/landing-page/ctaCard';
import {
  GatsbyImageDataParent,
  ColorPickerData,
  useGlobalState,
  getLaptop,
  getProductBySku,
} from '../components/GlobalState';

export type LandingPageComponentProps = {
  id: string;
  title: string;
  __typename: string;
  subtitle?: string;
  layoutVariations?: string;
  image?: GatsbyImageDataParent;
  imageAlt?: string;
  backgroundImage?: string;
  gradientStart?: ColorPickerData;
  gradientStop?: ColorPickerData;
  textBlocks?: Array<any>;
  gradientColor?: ColorPickerData;
  gradient?: ColorPickerData;
  ctaLink?: string;
  ctaText?: string;
  ctaAction?: string;
  footnotes?: string;
  productSku?: string;
  description?: string;
};

export type LandingPageProps = {
  pageContext: {
    landingPage: {
      id: string;
      slug?: string;
      title: string;
      description: string;
      pageComponents: LandingPageComponentProps[];
      urlPath?: string;
    };
  };
};

enum ComponentTypes {
  'Masthead' = 'GraphCMS_Masthead',
  'OffsetBox' = 'GraphCMS_LandingPageOffsetBox',
  'TextAndImage' = 'GraphCMS_LandingPageTextImage',
  'TextOnly' = 'GraphCMS_LandingPageTextBox',
  'TextList' = 'GraphCMS_LandingPageTextList',
  'VideoCard' = 'GraphCMS_LandingPageVideoCard',
  'CtaCard' = 'GraphCMS_LandingPageCtaCard',
  'CenteredText' = 'GraphCMS_LandingPageCenteredText',
}

/**
 * Fix a big gap between the masthead and the first card.
 */
const StyledCardWrapperGrid = styled(Grid)`
  &&.lpCardWrapper--mobile:first-of-type {
    padding-top: 0;
  }
`;

export const LpCardWrapper: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <StyledCardWrapperGrid
      item
      container
      className={`lpCardWrapper ${isMobile ? 'lpCardWrapper--mobile' : ''}`}
      xs={12}
      sm={6}
      height={{
        xs: 'auto',
        sm: '400px',
        md: '575px',
      }}
    >
      {children}
    </StyledCardWrapperGrid>
  )
};

/**
 * Style the grid container to adjust margins around the .lpOffsetBox depending on it's siblings.
 */
const LandingPageGridContainer = styled(Grid)`
  /* Override some styles for soft launch landing page */
  &&.lpGridContainer__soft-launch {
    .lpTextOnly__SectionContainerGrid {
      padding-bottom: 48px;
      .lpTextOnlyVariant__oneBlock {
        flex-direction: column;
      }
    }
    .lpTextOnly__wrap {
      background: linear-gradient(
        rgba(6, 7, 10, 0.5),
        rgba(28, 53, 93, 0.5)
      ) !important;
    }
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    &&.lpGridContainer__soft-launch
      .lpTextOnly__SectionContainerGrid
      .lpTextOnlyVariant__oneBlock {
      flex-direction: row;
    }
  }
`;

/**
 * Determine if the landing page has any card components.
 * @param components Page components
 * @returns True if any of the components are a card.
 */
const hasCardComponents = (components: LandingPageComponentProps[]): boolean =>
  components.some(
    (component: LandingPageComponentProps) =>
      component.__typename === ComponentTypes.VideoCard ||
      component.__typename === ComponentTypes.CtaCard,
  );

export const CardContainer: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Grid
      className="lpCardContainer"
      container
      item
      justifyContent="center"
      alignItems="center"
      width="100%"
      direction={isMobile ? 'column' : 'row'}
      rowSpacing={{
        xs: theme.spacing(16),
        sm: theme.spacing(3),
        md: theme.spacing(4),
      }}
      columnSpacing={{
        xs: 0,
        sm: theme.spacing(3),
        md: theme.spacing(4),
      }}
      padding={{
        xs: 0,
        sm: `0 ${theme.spacing(3)} 0 0`,
        md: `0 ${theme.spacing(4)} 0 0`,
      }}
      margin={{
        xs: '0 auto',
        md: `0 auto ${theme.spacing(7)} auto`,
      }}
      style={{
        width: '100%',
        maxWidth: theme.breakpoints.values.xl,
      }}
    >
      {children}
    </Grid>
  );
};

const LandingPage: React.FC<LandingPageProps> = ({ pageContext }) => {
  const { landingPage } = pageContext;
  const theme = useTheme();
  const [{ products }] = useGlobalState();
  const devOne = getLaptop(products);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Layout hideFooterLinks>
      <SEO title={landingPage.title} description={landingPage.description} />
      <LandingPageGridContainer
        container
        justifyContent="center"
        direction="column"
        bgcolor={theme.palette.greyscale.black}
        className={`lpGridContainer lpGridContainer__${landingPage.urlPath}`}
      >
        {landingPage?.pageComponents?.map((component) => {
          if (component.__typename === ComponentTypes.Masthead) {
            return <Masthead key={component.id} {...component} />;
          }
          if (component.__typename === ComponentTypes.OffsetBox) {
            return <OffsetBox key={component.id} {...component} />;
          }
          if (component.__typename === ComponentTypes.TextAndImage) {
            return (
              <TextAndImageSection
                key={component.id}
                product={devOne}
                {...component}
              />
            );
          }
          if (component.__typename === ComponentTypes.TextOnly) {
            return (
              <TextOnlySection
                key={component.id}
                product={devOne}
                {...component}
              />
            );
          }
          if (component.__typename === ComponentTypes.TextList) {
            return <TextList key={component.id} {...component} />;
          }
          if (component.__typename === ComponentTypes.CenteredText) {
            return <CenteredTextSection key={component.id} {...component} />;
          }
          return null;
        })}
        {hasCardComponents(landingPage.pageComponents) && (
          <CardContainer>
            {landingPage?.pageComponents?.map((component) => {
              if (component.__typename === ComponentTypes.VideoCard) {
                return (
                  <LpCardWrapper key={component.id}>
                    <VideoCard {...component} />
                  </LpCardWrapper>
                );
              }
              if (component.__typename === ComponentTypes.CtaCard) {
                return (
                  <LpCardWrapper key={component.id}>
                    <CtaCard {...component} />
                  </LpCardWrapper>
                );
              }
              return null;
            })}
          </CardContainer>
        )}
      </LandingPageGridContainer>
    </Layout>
  );
};

export default LandingPage;
