import React from 'react';
import { Grid, Typography, useTheme, useMediaQuery } from '@mui/material';
import StyledType from '../styledType';
import { Product, AppAction, GraphCmsRichText } from '../../GlobalState';
import LandingPageCtaButton from '../LandingPageCtaButton';
import TwoColumnTextGrid from '../twoColumnTextGrid';

const TwoTextBlocksVariant: React.FC<{
  title: string;
  ctaText?: string;
  ctaLink?: string;
  ctaAction?: AppAction;
  textBlocks: Array<GraphCmsRichText>;
  product: Product;
  subtitle?: string;
}> = ({
  title,
  subtitle,
  ctaText,
  ctaLink,
  ctaAction,
  textBlocks,
  product,
}) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
  const isMobile = useMediaQuery(theme.breakpoints.only('xs'));
  return (
    <Grid
      container
      item
      direction="column"
      className="lpTextOnlyVariant__twoBlocks"
      spacing={theme.spacing(6)}
    >
      {title ? (
        <Grid
          xs={12}
          sm={6}
          style={{
            paddingTop: 0,
            marginBottom: subtitle ? theme.spacing(1) : 0,
          }}
        >
          <Typography
            textAlign="left"
            component="h2"
            variant={isDesktop ? 'h2' : 'h4'}
          >
            {title}
          </Typography>
        </Grid>
      ) : null}

      {subtitle ? (
        <Grid xs={12} sm={6}>
          <Typography
            textAlign="left"
            component="h3"
            variant={isDesktop ? 'h3' : 'h5'}
          >
            {subtitle}
          </Typography>
        </Grid>
      ) : null}
      <TwoColumnTextGrid>
        <Grid item xs={12} sm={6}>
          <StyledType textBlock={textBlocks[0]} textAlign="left" />

          {isDesktop ? (
            <LandingPageCtaButton
              sx={{ marginTop: theme.spacing(6) }}
              ctaText={ctaText}
              ctaLink={ctaLink}
              ctaAction={ctaAction}
              product={product}
            />
          ) : null}
        </Grid>
        <Grid item xs={12} sm={6}>
          {textBlocks && textBlocks[0] && (
            <StyledType textBlock={textBlocks[1]} textAlign="left" />
          )}
        </Grid>
      </TwoColumnTextGrid>
      {isMobile ? (
        <LandingPageCtaButton
          sx={{ marginTop: theme.spacing(6) }}
          ctaText={ctaText}
          ctaLink={ctaLink}
          ctaAction={ctaAction}
          product={product}
        />
      ) : null}
    </Grid>
  );
};

export default TwoTextBlocksVariant;
