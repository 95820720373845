import React from 'react';
import { Grid, useTheme } from '@mui/material';
import VariantOne from './variantOne';
import VariantTwo from './variantTwo';
import VariantThree from './variantThree';
import VariantFour from './variantFour';
import MastheadContent from './mastheadContent';
import {
  GatsbyImageDataParent,
  ColorPickerData,
  GraphCmsRichText,
  mastheadVariantList,
  MastheadVariants,
} from '../../GlobalState';

const Masthead: React.FC<{
  title: string;
  subtitle?: string;
  layoutVariations: MastheadVariants;
  gradient: ColorPickerData;
  image: GatsbyImageDataParent;
  imageAlt: string;
  textBlocks: Array<GraphCmsRichText>;
}> = ({
  title,
  subtitle,
  layoutVariations,
  gradient,
  image,
  imageAlt,
  textBlocks,
}) => {
  const { r, g, b } = gradient?.rgba || {};
  const theme = useTheme();
  return (
    <Grid
      className="lpMasthead"
      container
      justifyContent="center"
      alignItems="center"
      sx={{
        background: gradient.rgba
          ? `radial-gradient(ellipse at bottom, rgba(${r},${g},${b},1), rgba(${r},${g},${b},0))`
          : theme.palette.greyscale.black,
      }}
    >
      {layoutVariations === mastheadVariantList[0] && (
        <VariantOne
          title={title}
          subtitle={subtitle}
          image={image?.gatsbyImageData}
          imageAlt={imageAlt}
          textBlocks={textBlocks}
        />
      )}

      {layoutVariations === mastheadVariantList[1] && (
        <VariantTwo
          title={title}
          image={image?.gatsbyImageData}
          imageAlt={imageAlt}
          textBlocks={textBlocks}
        />
      )}

      {layoutVariations === mastheadVariantList[2] && (
        <VariantThree
          title={title}
          subtitle={subtitle}
          image={image?.gatsbyImageData}
          imageAlt={imageAlt}
          textBlocks={textBlocks}
        />
      )}
      {layoutVariations === mastheadVariantList[3] && (
        <VariantFour title={title} textBlock={textBlocks[0]} />
      )}
      {(layoutVariations === mastheadVariantList[4] ||
        layoutVariations === mastheadVariantList[5]) && (
        <MastheadContent
          title={title}
          subtitle={subtitle}
          image={image?.gatsbyImageData}
          imageAlt={imageAlt}
          textBlocks={textBlocks}
          layout={layoutVariations}
        />
      )}
    </Grid>
  );
};

export default Masthead;
