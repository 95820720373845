import React from 'react';
import { useTheme, Grid, SxProps, Theme, styled } from '@mui/material';

const StyledGrid = styled(Grid)(
  ({ theme }) => `
    &&.sectionContainerGrid { 
      width: 100%;
      ${theme.breakpoints.only('xs')} {
        padding: ${theme.spacing(6)} ${theme.spacing(3)} ${theme.spacing(
    6,
  )} ${theme.spacing(3)};
      }
      ${theme.breakpoints.only('sm')} {
        padding: ${theme.spacing(6)} ${theme.spacing(4)} ${theme.spacing(
    6,
  )} ${theme.spacing(4)};
      }
      ${theme.breakpoints.up('md')} {
        padding-top: ${theme.spacing(8)};
        padding-bottom: ${theme.spacing(8)};
      }
      &.lpOffsetBox {
        padding-left: 0;
        padding-right: 0;
      }
    }
`,
);

/**
 * Wrapping container for variants.
 */
const SectionContainerGrid: React.FC<{
  className: string;
  children: React.ReactNode;
  direction?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
  sx?: SxProps<Theme>;
  style?: React.CSSProperties;
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
}> = ({ className, children, direction, sx, style, xs, sm, md, lg, xl }) => {
  const theme = useTheme();
  return (
    <StyledGrid
      container
      className={`${className} sectionContainerGrid`}
      spacing={theme.spacing(6)}
      direction={direction || 'column'}
      alignItems="center"
      justifyContent="center"
      paddingTop={{ xs: theme.spacing(6) }}
      paddingBottom={{ xs: theme.spacing(3) }}
      paddingLeft={{ xs: theme.spacing(3), sm: theme.spacing(4) }}
      paddingRight={{ xs: theme.spacing(3), sm: theme.spacing(4) }}
      marginTop={0}
      marginLeft={0}
      sx={sx}
      style={{ maxWidth: theme.breakpoints.values.xl, ...style }}
      xs={xs || 12}
      sm={sm}
      md={md || 10}
      lg={lg}
      xl={xl}
    >
      {children}
    </StyledGrid>
  );
};

export default SectionContainerGrid;
