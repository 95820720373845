import React from 'react';
import { useMediaQuery, useTheme, styled } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { RichText } from '@graphcms/rich-text-react-renderer';
import { RichTextContent } from '@graphcms/rich-text-types';
import theme from '../theme';
import getChildrenOfType from '../utils/get-children-of-type';
import SpecTable, { Row } from './SpecTable';

export interface TableProps {
  content: RichTextContent;
  title?: string;
  customMobileLayout?: boolean;
}

const CustomTableContainer = styled(TableContainer)`
  border: 1px solid ${theme.palette.greyscale['04']};
  border-radius: 10px;
  margin: 2rem 0;
  tr td:first-of-type,
  th {
    font-weight: 900;
  }
  td,
  th {
    border-bottom: 1px solid ${theme.palette.greyscale['04']};
  }
`;

/**
 * Munge a 3xX table into rows for two SpecTables.
 * @param content Table from GraphCMS.
 */
const getMobileRows = (content: RichTextContent): Row[][] => {
  const tableRowList = getChildrenOfType('table_row', content);
  const rows: Row[][] = [];
  if (tableRowList?.length > 0) {
    rows[0] = [];
    rows[1] = [];
    const headerRow = tableRowList[0];
    const titleList = getChildrenOfType('text', headerRow);
    rows[0].push({ title: titleList[1] });
    rows[1].push({ title: titleList[2] });
    tableRowList.forEach((tableRow, index) => {
      if (index > 0) {
        const title = getChildrenOfType('text', tableRow.children[0])[0];
        rows[0].push({
          title,
          description: getChildrenOfType('text', tableRow.children[1])[0],
        });
        rows[1].push({
          title,
          description: getChildrenOfType('text', tableRow.children[2])[0],
        });
      }
    });
  }
  return rows;
};

const AccessibleTable: React.FC<TableProps> = ({
  content,
  title,
  customMobileLayout = false,
}) => {
  const MUItheme = useTheme();
  const isMobile = useMediaQuery(MUItheme.breakpoints.down('sm'));
  const specTableRowsList = getMobileRows(content);
  return isMobile && customMobileLayout ? (
    <>
      {specTableRowsList.map((rows, index) => (
        <SpecTable
          rows={rows}
          title={rows?.[0]?.title}
          specMarginBottom={index < specTableRowsList.length - 1 ? 5 : 0}
          key={rows?.[0]?.title?.replace(' ', '-')}
        />
      ))}
    </>
  ) : (
    <CustomTableContainer>
      <Table aria-label={title}>
        <RichText
          content={content}
          renderers={{
            table_head: ({ children: th }) => <TableHead>{th}</TableHead>,
            table_row: ({ children: tr }) => <TableRow>{tr}</TableRow>,
            table_cell: ({ children: td }) => <TableCell>{td}</TableCell>,
            table_body: ({ children: tb }) => <TableBody>{tb}</TableBody>,
          }}
        />
        {title ? <caption>{title}</caption> : null}
      </Table>
    </CustomTableContainer>
  );
};

export default AccessibleTable;
