// This is just to factor the slug extraction rules into a common
// place that's easy to mantain.

// It's a .js file because this function is used in gatsby-node.js

const getSlug = (text) =>
  text
    .replace(/[.?:/]/g, '')
    .toLowerCase()
    .split(/\s+/)
    .join('-');

module.exports = getSlug;
