import React from 'react';
import { Grid, Typography } from '@mui/material';
import { RichText } from '@graphcms/rich-text-react-renderer';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import { GraphCmsRichText } from '../../GlobalState';
import SectionContainerGrid from '../sectionContainerGrid';
import TwoColumnTextGrid from '../twoColumnTextGrid';
import Image from '../../Image';

const VariantThree: React.FC<{
  title: string;
  subtitle?: string;
  image: IGatsbyImageData;
  imageAlt: string;
  textBlocks: Array<GraphCmsRichText>;
}> = ({ title, subtitle, image, imageAlt, textBlocks }) => (
  <SectionContainerGrid className="lpMastheadVariant__variantThree">
    <Grid
      item
      container
      className="lpMastheadVariant__imageTextWrap"
      direction={{
        xs: 'column-reverse',
        sm: 'row-reverse',
      }}
    >
      <Grid className="lpMastheadVariant__title" item xs={12} sm={5}>
        <Typography
          textAlign="center"
          sx={{ color: 'text.primary' }}
          variant="h1"
        >
          {title}
        </Typography>
      </Grid>
      <Grid className="lpMastheadVariant__image" item xs={12} sm={7}>
        {image && <Image image={image} alt={imageAlt} />}
      </Grid>
    </Grid>

    {subtitle && (
      <Grid container item className="lpMastheadVariant__subTitleWrap">
        <Grid item className="lpMastheadVariant__spacer" xs={1} />
        <Grid item className="lpMastheadVariant__subTitle" xs={11}>
          <Typography
            textAlign="left"
            sx={{ color: 'text.primary' }}
            variant="h2"
          >
            {subtitle}
          </Typography>
        </Grid>
      </Grid>
    )}

    <TwoColumnTextGrid>
      <Grid className="lpMastheadVariant__textBlock[0]" item xs={12} sm={6}>
        {textBlocks[0] && <RichText content={textBlocks[0].raw} />}
      </Grid>

      <Grid className="lpMastheadVariant__textBlock[1]" item xs={12} sm={6}>
        {textBlocks[1] && <RichText content={textBlocks[1].raw} />}
      </Grid>
    </TwoColumnTextGrid>
  </SectionContainerGrid>
);

export default VariantThree;
