/* eslint-disable no-nested-ternary */
import React from 'react';
import { Grid, Typography, useTheme, useMediaQuery } from '@mui/material';
import { AppAction, GraphCmsRichText, Product } from '../../GlobalState';
import LandingPageCtaButton from '../LandingPageCtaButton';
import StyledType from '../styledType';

const NoTextBlocksVariant: React.FC<{
  title?: string;
  subtitle?: string;
  ctaText?: string;
  ctaLink?: string;
  ctaAction?: AppAction;
  bodyText?: GraphCmsRichText;
  product?: Product;
}> = ({ title, subtitle, ctaText, ctaLink, ctaAction, bodyText, product }) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
  const isMobile = useMediaQuery(theme.breakpoints.only('xs'));
  return (
    <Grid
      container
      item
      direction="column"
      className="lpCenteredTextVariant"
      spacing={theme.spacing(6)}
      alignItems="center"
      justifyContent="center"
    >
      <Grid className="textWrapper" container direction="row">
        <Grid className="spacer" xs={0} sm={1} md={2} />
        <Grid item xs={12} sm={10} md={8} className="titleWrapper">
          {title ? (
            <Grid className="titleGrid" style={{ paddingTop: 0 }}>
              <Typography
                textAlign="center"
                component="h2"
                variant={isDesktop ? 'h2' : 'h4'}
                style={{
                  marginBottom: subtitle ? theme.spacing(1) : theme.spacing(2),
                }}
              >
                {title}
              </Typography>
            </Grid>
          ) : null}

          {subtitle ? (
            <Grid
              className="subtitleGrid"
              xs={12}
              sm={6}
              style={{ maxWidth: '100%' }}
            >
              <Typography
                textAlign="center"
                component="h3"
                variant={isDesktop ? 'h3' : 'h5'}
                style={{
                  marginBottom: theme.spacing(2),
                }}
              >
                {subtitle}
              </Typography>
            </Grid>
          ) : null}
        </Grid>
        <Grid className="spacer" xs={0} sm={1} md={2} />
      </Grid>

      {bodyText ? <StyledType textBlock={bodyText} textAlign="center" /> : null}

      {ctaText && ctaAction ? (
        <LandingPageCtaButton
          sx={{
            marginTop: theme.spacing(5),
            width: isMobile ? '100%' : 'auto',
          }}
          ctaText={ctaText}
          ctaLink={ctaLink}
          ctaAction={ctaAction}
          product={product}
        />
      ) : null}
    </Grid>
  );
};

export default NoTextBlocksVariant;
